import React from 'react'
import Router from 'next/router'
import { NextPage, NextPageContext } from 'next'
import cookies from 'next-cookies'

import { HomeTemplate } from '@Components/templates'

const HomePage = () => {
  return <HomeTemplate />
}
;(HomePage as NextPage).getInitialProps = async (ctx: NextPageContext) => {
  const _cookies = cookies(ctx)

  if (_cookies.jwtIdToken) {
    if (ctx.res) {
      ;(ctx.res as any).redirect('/projects')
      ctx.res.end()
      return {}
    }

    Router.push('/projects')
    return {}
  }

  return {}
}

export default HomePage
